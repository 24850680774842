@import url(https://unpkg.com/@tailwindcss/typography@0.4.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap);
* {
  font-family: "Space Grotesk",
    sans-serif;
}
.cursive {
  font-family: "Bebas Neue";
  font-weight: 700;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}

.peach {
  background-color: cornsilk;
}
.yellowbg {
  background-color: rgba(
    244,
    191,
    60,
    1
  );
}
.textout {
  color: cornsilk;
}

